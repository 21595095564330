.cid-idp-selector-popup-info-box {
  padding: 12px 12px 12px 20px;
  background-color: #e3f4ec;
  border-radius: 8px;
  position: relative;
  /* matches participants width */ 
  /* max-width: 380px; */
}
.cid-idp-selector-popup-info-box--info {
  background-color: #e3f4ec;
}
.cid-idp-selector-popup-info-box--warning {
  background-color: #FFF9F5;
}
.cid-idp-selector-popup-info-box::before {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #33b27f;
  height: 100%;
  width: 8px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  content: '';
}
.cid-idp-selector-popup-info-box--warning::before {
  background-color: #FFC1A0;
}
.cid-idp-selector-popup-info-box--info .cid-idp-selector-popup-info-box::before {
  background-color: #FFC1A0;
}
.cid-idp-selector-popup-info-box--warning .cid-idp-selector-popup-info-box::before {
  background-color: #FFC1A0;
}
.cid-idp-selector-popup-info-box-container {
  display: flex;
}
.cid-idp-selector-popup-info-box-content {
  margin-left: 20px;
}
.cid-idp-selector-popup-info-box-icon {
  flex-shrink: 0;
}
