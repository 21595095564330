.cid-idp-selector-popup {
  background: white;
  box-shadow: 0 10px 10px -1px rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 60px 120px;
  box-sizing: border-box;
  height: 100vh;
}

.cid-idp-selector-popup::-webkit-scrollbar {
  display: none;
}
.cid-idp-selector-popup-close {
  padding: 12px;
  border-radius: 12px;
  background-color: #f4f4f4;
  color: var(--charcoal);
  font-size: 13px;
  font-family: var(--font);
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
}
.cid-idp-selector-popup-close:hover {
  background-color: #c9c9c9;
}
.cid-idp-selector-popup-close::before {
  content: 'Close';
}
.cid-idp-selector-popup-close > svg {
  fill: black;
  margin-left: 4px;
  width: 16px;
  height: 16px;
}
.cid-idp-selector-popup-logo-container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.cid-idp-selector-popup-logos {
  display: flex;
  align-items: center;
}
.cid-idp-selector-popup-logo {
  background: var(--charcoal);
  fill: white;
  padding: 8px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 72px;
  height: 72px;
  box-sizing: border-box;
  border-radius: 12px;
}
.cid-idp-selector-popup-logo-dots {
  display: flex;
  margin: 0 8px;
}
.cid-idp-selector-popup-logo-dots > div:nth-child(1) {
  background: grey;
}
.cid-idp-selector-popup-logo-dots > div:nth-child(2) {
  margin: 0 12px;
  background: darkgrey;
}
.cid-idp-selector-popup-logo-dots > div:nth-child(3) {
  background: lightgrey;
}
.cid-idp-selector-popup-logo-dot {
  border-radius: 50%;
  width: 8px;
  height: 8px;
}
.cid-idp-selector-popup-logo-rp {
  width: 72px;
  height: 72px;
  border-radius: 12px;
}
.cid-idp-selector-popup-title {
  font-size: 36px;
  color: var(--charcoal);
  font-family: var(--font-bold);
  margin: 0;
  margin-bottom: 8px;
}
.cid-idp-selector-popup-description {
  font-size: 13px;
  line-height: 16px;
  color: #5c5c5c;
  font-family: var(--font);
  margin: 0;
  margin-bottom: 30px;
}
.cid-idp-selector-popup-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cid-idp-selector-popup-subtitle {
  font-size: 24px;
  line-height: 28px;
  color: var(--charcoal);
  font-family: var(--font-bold);
  margin: 0;
}
.cid-idp-selector-popup-claims-toggle {
  display: none;
  fill: var(--charcoal);
}
.cid-idp-selector-popup-claims-toggle--open {
  transform: rotate(180deg);
}
.cid-idp-selector-popup-claims {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  margin-top: 20px;
  margin-bottom: 30px;
}
.cid-idp-selector-popup-claims.cid-idp-selector-popup-claims--visible {
  display: grid;
}
.cid-idp-selector-popup-claim {
  font-family: var(--font);
  font-size: 14px;
  color: var(--charcoal);
  display: flex;
  align-items: center;
}
.cid-idp-selector-popup-claim > svg {
  margin-right: 8px;
  flex-shrink: 0;
}
.cid-idp-selector-popup-manualonly {
  margin-top: 70px;
  width: 515px; /* so it matches the claims grid */
}
.cid-idp-selector-popup-manualonly-info {
  display: flex;
  margin-bottom: 36px;
}
.cid-idp-selector-popup-manualonly-icon {
  margin-right: 15px;
  flex-shrink: 0;
}
.cid-idp-selector-popup-manualonly-title {
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  margin-right: 15px;
  margin-bottom: 12px;
  color: var(--charcoal);
  font-family: var(--font);
}
.cid-idp-selector-popup-manualonly-description {
  font-size: 14px;
  line-height: 18px;
  color: var(--charcoal);
  margin: 0;
  font-family: var(--font);
}
.cid-idp-selector-popup-manualonly-button {
  background-color: #e9e9e9;
  width: 100%;
  text-align: center;
  padding: 12px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 12px;
  font-size: 14px;
  font-family: var(--font);
  line-height: 18px;
  color: var(--charcoal);
  border: none;
}
.cid-idp-selector-popup-skeleton-container {
  margin-top: 30px;
}
.cid-idp-selector-popup-participants {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  max-height: 225px;
  overflow-y: scroll;
}
.cid-idp-selector-popup-participants::-webkit-scrollbar {
  display: none;
}
.cid-idp-selector-popup-participant {
  display: flex;
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  box-sizing: border-box;
  padding: 8px 16px;
  border: 1px solid #e9e9e9;
}
.cid-idp-selector-popup-participant-icon {
  width: 32px;
  height: 32px;
  border-radius: 8px;
}
.cid-idp-selector-popup-participant:hover {
  border: 1px solid lightgrey;
}
.cid-idp-selector-popup-participant-name {
  font-size: 14px;
  font-family: var(--font);
  margin-left: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 90%;
}
.cid-idp-selector-popup-manual {
  font-family: var(--font);
  margin: 0;
  margin-top: 30px;
  text-align: center;
}
.cid-idp-selector-popup-manual-title {
  color: #5c5c5c;
  display: block;
}
.cid-idp-selector-popup-manual-action {
  font-family: var(--font-bold);
  text-decoration: underline;
  cursor: pointer;
  display: block;
  margin-top: 4px;
}
.cid-idp-selector-popup-manual-description {
  font-size: 12px;
  color: #5c5c5c;
  display: block;
  margin: 20px auto 0 auto;
  max-width: 300px;
}
.cid-idp-selector-popup-info-box-error-wrapper {
  margin-top: 24px;
}
.cid-idp-selector-popup-info-box-title {
  font-size: 16px;
  font-family: var(--font-bold);
  margin: 0;
  margin-bottom: 4px;
  color: #262626;
}
.cid-idp-selector-popup-info-box-text {
  margin: 0;
  font-size: 13px;
  font-family: var(--font);
  line-height: 16px;
}
.cid-idp-selector-popup-info-box-link {
  color: var(--charcoal);
  font-size: 13px;
  font-family: var(--font-bold);
  width: fit-content;
  text-decoration: none;
}

.cid-idp-selector-popup-info-box-link-underline {
  color: var(--charcoal);
  font-family: var(--font-bold);
  width: fit-content;
  text-decoration: underline;
  font-weight: bold;
}

@media (max-width: 768px) {
  .cid-idp-selector-popup-manualonly {
    width: 100%;
  }
  .cid-idp-selector-popup {
    width: 100%;
    padding: 40px;
  }
  .cid-idp-selector-popup-claims {
    grid-template-columns: repeat(2, 165px);
  }
  .cid-idp-selector-popup-manual {
    text-align: left;
  }
  .cid-idp-selector-popup-manual-description {
    margin: 20px 0;
  }
  .cid-idp-selector-popup-participants {
    grid-template-columns: 190px 190px;
  }
}

@media (max-width: 482px) {
  .cid-idp-selector-popup-manualonly {
    margin-top: 0;
  }
  .cid-idp-selector-popup-manualonly-icon {
    display: none;
  }
  .cid-idp-selector-popup-manual {
    text-align: center;
  }
  .cid-idp-selector-popup-manual-title {
    font-size: 14px;
  }
  .cid-idp-selector-popup-manual-action {
    background-color: #e9e9e9;
    width: 100%;
    text-align: center;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 12px;
    font-size: 14px;
    font-family: var(--font);
    text-decoration: none;
    margin-top: 12px;
  }
  .cid-idp-selector-popup-manual-description {
    margin: 20px auto 0 auto;
  }
  .cid-idp-selector-popup-participants {
    margin-top: 12px;
    grid-template-columns: 1fr;
  }
  .cid-idp-selector-popup-participant-name {
    overflow: inherit;
    white-space: normal;
    text-overflow: inherit;
    width: 100%;
    font-size: 16px;
  }
  .cid-idp-selector-popup-close {
    position: absolute;
    padding: 8px;
    right: 0;
  }
  .cid-idp-selector-popup-close::before {
    content: '';
  }
  .cid-idp-selector-popup-close > svg {
    margin-left: 0;
  }
  .cid-idp-selector-popup-claims-toggle {
    display: block;
  }
  .cid-idp-selector-popup-logo {
    width: 56px;
    height: 56px;
  }
  .cid-idp-selector-popup-logos {
    justify-content: center;
  }
  .cid-idp-selector-popup-logo-container {
    margin-bottom: 12px;
    justify-content: center;
  }
  .cid-idp-selector-popup-title-description-container {
    text-align: center;
  }
  .cid-idp-selector-popup-logo-dots {
    margin: 0 6px;
  }
  .cid-idp-selector-popup-logo-dots > div:nth-child(2) {
    margin: 0 8px;
    background: darkgrey;
  }
  .cid-idp-selector-popup-logo-dot {
    border-radius: 50%;
    width: 6px;
    height: 6px;
  }
  .cid-idp-selector-popup-logo-rp {
    width: 56px;
    height: 56px;
  }
  .cid-idp-selector-popup-claims {
    display: none;
    margin-top: 12px;
    margin-bottom: 0;
    grid-template-columns: repeat(auto-fit, 165px);
  }
  .cid-idp-selector-popup-title {
    font-size: 26px;
  }
  .cid-idp-selector-popup-subtitle {
    font-size: 18px;
  }
  .cid-idp-selector-popup {
    background-color: #f4f4f4;
  }
  .cid-idp-selector-popup-provider-wrapper {
    background-color: white;
    padding: 20px;
    border-radius: 12px;
  }
  .cid-idp-selector-popup-claims-wrapper {
    background-color: white;
    padding: 20px;
    border-radius: 12px;
    margin-bottom: 20px;
  }
  .cid-idp-selector-popup-info-box-error-wrapper {
    margin-top: 12px;
  }
}
