#green-id-area #greenid-container .show-desktop.preview-info-container,
#green-id-area #greenid-container .file-upload-container,
#green-id-area #greenid-container .dark-inner-provider-container {
  border-radius: 12px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 1rem !important;
}
#green-id-area #greenid-container .file-upload-container {
  max-width: 100% !important;
}
#green-id-area #greenid-container .dark-inner-provider-container {
  width: 100% !important;
  max-width: 100% !important;
}
#green-id-area .greenid-so-content #greenid-container .btn-back {
  margin-left: 0;
}

#green-id-area #fileuploadBtn,
#green-id-area .camera-viewfinder--overlay-text button {
  border-radius: 12px !important;
}

#green-id-area #greenid-container .btn.btn-complete-later.greenid-modal-launch {
  margin-right: 0;
}
#green-id-area {
  margin-top: 0.75rem;
}
#green-id-area .btn.btn-complete-later,
#greenid-source-cancel, #green-id-area .btn.btn-cancel {
  opacity: 0;
  visibility: hidden !important;
}
#green-id-area .greenid-modal .modal-header,
#green-id-area .greenid-modal .modal-footer {
  border: none;
}
#green-id-area .greenid-modal .modal-dialog {
  align-items: center;
  display: flex;
  height: 100%;
}
#green-id-area #greenid-error .modal-dialog {
  transform: translate(calc(50% - 260px), 0);
}
#green-id-area .greenid-modal .modal-content {
  width: 100%;
  border-radius: 30px;
}
#green-id-area #greenid-complete-modal {
  margin-left: calc(50% - 260px);
}

#green-id-area .mb-0 {
  margin-bottom: 0!important;
}

#green-id-area #greenid-container select {
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  line-height: 1.42857143;
  background-image: none;
  border: 1px solid #ccc;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  line-height: 1.4375em;
  font-weight: 400;
  font-family: 'SF Pro Display', sans-serif;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 46px;
  position: relative;
  font-size: 16px;
  border-radius: 12px;
}
#green-id-area #greenid-container .form-control {
  font-size: 18px;
  line-height: 1.4375em;
  font-weight: 400;
  font-family: 'SF Pro Display', sans-serif;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 46px;
  position: relative;
  font-size: 16px;
  border-radius: 0;
  background-color: #ffffff;
  border: 1px solid #929293;
}
#green-id-area #greenid-container a {
  color: #003e4e;
}
#green-id-area #greenid-container button.btn-default {
  border: 1px solid #929293 !important;
  display: flex;
  background-color: #FFFFFF;
  border-radius: 0 !important;
  height: 46px;
}

#green-id-area #greenid-container .btn,
#green-id-area #greenid-container button.btn {
  border: 0;
  padding-bottom: 8px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 8px;
  border-radius: 12px;
  line-height: 1.75;
  font-size: 16px;
}
#green-id-area #greenid-container .btn-primary,
#green-id-area
  #greenid-biometric-content
  .greenid-so-content
  #greenid-container
  #webscanRoot
  .button.button--primary,
#green-id-area .greenid-modal .modal-footer button {
  color: #fff;
  background-color: #003e4e;
  border: none;
}
#green-id-area .greenid-modal .modal-footer button {
  padding: 10px 12px 10px 12px;
  border-radius: 12px;
}
#green-id-area .greenid-modal{
  overflow: unset;
}
#green-id-area #greenid-container select:focus,
#green-id-area #greenid-container .form-control:focus {
  border-color: #212121;
  outline: 0;
  box-shadow: none;
}
#green-id-area #greenid-container .greenid-has-error .control-label,
#green-id-area #greenid-container .greenid-has-error .help-block {
  color: #ff3f52;
}
#green-id-area #greenid-container .greenid-has-error .form-control {
  border-color: #ff3f52;
  box-shadow: none;
}
#green-id-area #greenid-container .dropdown-menu {
  border-radius: 12px;
}
#green-id-area #greenid-container .list-group-item:first-child {
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}
#green-id-area #greenid-container .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}
#green-id-area #greenid-container #greenid-source-content .dd-select {
  border-radius: 12px;
}
.greenid-datepicker-container.dropdown-menu {
  z-index: 1300 !important;
  padding: 5px !important;
  border-radius: 12px !important;
}
.greenid-tooltip {
  z-index: 1300 !important;
}
.greenid-tooltip .tooltip-inner {
  border-radius: 12px !important;
  background-color: rgb(0, 0, 0, 0.9) !important;
}
#green-id-area #greenid-container .input-group input.form-control {
  border-radius: 0 !important;
}
#green-id-area #greenid-container .input-group-addon {
  border: 1px solid #929293;
  border-left: 0;
}

#green-id-area #greenid-container .input-group-addon:last-child {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
#green-id-area #greenid-container .greenid-so-content-dropcap-container {
  flex-direction: column;
  padding: 0.375rem;
}
#green-id-area
  #greenid-container
  .greenid-so-content-dropcap-container
  .greenid-so-content-dropcap-listitem {
  margin-bottom: 0.75rem;
}
#greenid-so-process-overview-button {
  padding-right: 60px !important;
}
#green-id-area .greenid-so-content-select {
  flex-direction: column;
  width: 100%;
  max-width: 100%;
}
#green-id-area .greenid-so-content-select .greenid-so-content-select-container {
  max-width: 100% !important;
  margin: 0 !important;
  border-radius: 12px;
}
#green-id-area .greenid-so-content-select .greenid-so-content-select-container button {
  border: 0;
}
#green-id-area .greenid-so-content-select .greenid-so-content-select-container button:hover {
  background-color: #81828263;
}
#green-id-area #greenid-so-content-select-state-1 {
  border: none;
  box-shadow: none;
  background: #f9f9f9;
}
#green-id-area #greenid-container #greenid-intro-content h1 {
  font-weight: 500;
  color: black;
  font-size: 28px;
}

#green-id-area
  #greenid-biometric-content
  .greenid-so-content
  #greenid-container
  #webscanRoot
  .button {
  border-radius: 12px;
}

#green-id-area #greenid-biometric-content #greenid-container .form-control {
  border-right: 0;
}

#green-id-area #greenid-biometric-content #greenid-container button.btn-default {
  border-left: 0 !important;
}